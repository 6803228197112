<template>
  <main
    class=" w-full h-full bg-white dark:bg-black transition-all overflow-hidden"
  >
    <teleport to="head">
      <meta name="title" content="Hasolu" />
      <meta name="description" content="be creative" />
      <meta name="robots" content="index, follow" />

      <meta name="language" content="English" />
      <meta name="author" content="hasolu" />
    </teleport>
    <Navbar @davidoff="showDavid = false" />
    <div
      class="container mx-auto  flex h-[calc(100vh-8rem)] sm:h-[calc(100vh-12rem)] flex-col sm:flex-row  mt-12  "
    >
      <div
        class="w-full h-1/2 sm:h-full space-y-1  flex flex-col relative justify-center  pb-12 items-center "
      >
        <transition
          enter-active-class="animate__animated animate__slideInUp "
          leave-active-class="animate__animated animate__slideOutDown "
          mode="out-in"
        >
          <h1
            v-if="show"
            class="font-hslBold select-none dark:text-white text-[2.75rem] sm:text-[5rem]"
          >
            <span class="font-hslThin">be </span>
            {{ words[index] }}
          </h1>
        </transition>
        <div
          class="bg-white w-full transition-all dark:bg-black h-16 sm:h-36 z-10"
        ></div>
      </div>
      <div class="w-full h-full relative  ">
        <transition
          enter-active-class="animate__animated animate__slideInUp delay-1 "
          leave-active-class="animate__animated animate__slideOutDown "
          mode="out-in"
        >
          <span
            v-if="showDavid"
            class="w-full h-full absolute z-10 david"
          ></span>
        </transition>
        <transition
          enter-active-class="animate__animated animate__zoomIn delay-25 "
          leave-active-class="animate__animated animate__zoomOut "
          mode="out-in"
        >
          <span
            v-if="showDavid"
            class="w-full h-full absolute  david-bg dark:david-bgwhite"
          ></span>
        </transition>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  data() {
    return {
      words: [
        "creative.",
        "brave.",
        "hardworking.",
        "thoughtful.",
        "tidy.",
        "honest.",
      ],
      index: Math.floor(Math.random() * 6),
      show: false,
      showDavid: false,
      home: false,
    };
  },

  watch: {
    index() {
      setTimeout(() => {
        this.show = true;
      }, 500);
      setTimeout(() => {
        this.changeText();
      }, 5000);
    },
  },

  mounted() {
    this.show = true;
    this.showDavid = true;

    setTimeout(() => {
      this.changeText();
    }, 5000);
  },

  methods: {
    changeText() {
      const arrayLength = this.words.length - 1;
      if (this.index < arrayLength) {
        this.show = false;
        this.index += 1;
      } else {
        this.show = false;
        this.index = 0;
      }
    },
  },

  components: {
    Navbar,
  },
};
</script>
