<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="120pt"
    height="60pt"
    viewBox="0 0 8001.000000 4926.000000"
    preserveAspectRatio="xMidYMid meet"
    class="antialiased "
  >
    <g
      transform="translate(0.000000,4926.000000) scale(0.100000,-0.100000)"
      :fill="color"
      stroke="none"
    >
      <path
        d="M1690 24290 l0 -8520 1730 0 1730 0 3 3658 c3 3995 -1 3686 57 3997
117 621 400 1137 822 1500 410 352 904 553 1543 627 169 19 612 16 760 -5 417
-62 757 -194 1045 -408 103 -76 280 -247 362 -348 321 -398 503 -911 548
-1541 6 -95 10 -1418 10 -3812 l0 -3668 1715 0 1715 0 0 4043 c0 2601 -4 4101
-10 4207 -51 825 -231 1539 -555 2195 -224 455 -474 810 -810 1154 -372 380
-734 642 -1210 876 -573 282 -1195 444 -1900 496 -206 15 -670 15 -850 0 -573
-49 -1115 -174 -1625 -375 -193 -77 -572 -264 -743 -368 -342 -207 -636 -450
-816 -674 l-61 -75 0 2780 0 2781 -1730 0 -1730 0 0 -8520z"
      />
      <path
        d="M56900 24290 l0 -8520 1745 0 1745 0 0 8520 0 8520 -1745 0 -1745 0
0 -8520z"
      />
      <path
        d="M48495 28769 c-1608 -90 -3082 -687 -4231 -1712 -77 -68 -224 -211
-329 -318 -1333 -1362 -1995 -3227 -1839 -5187 172 -2173 1344 -4051 3209
-5142 178 -104 566 -298 759 -379 696 -293 1393 -463 2196 -537 239 -21 823
-30 1075 -15 1604 95 3017 656 4195 1665 135 116 540 521 656 656 840 980
1353 2110 1533 3375 47 330 55 471 56 920 0 455 -7 586 -51 915 -234 1755
-1135 3305 -2544 4374 -1207 917 -2705 1403 -4295 1394 -137 0 -313 -5 -390
-9z m801 -3144 c1460 -154 2593 -1145 2943 -2577 77 -313 105 -564 105 -928 0
-304 -12 -447 -59 -705 -200 -1085 -884 -2002 -1855 -2490 -530 -266 -1185
-402 -1772 -366 -730 44 -1347 273 -1889 702 -132 104 -401 373 -509 509 -613
772 -886 1842 -739 2900 106 762 426 1436 929 1954 578 596 1284 925 2170
1009 111 11 545 5 676 -8z"
      />
      <path
        d="M35285 28674 c-273 -16 -415 -28 -570 -45 -1200 -133 -2185 -506
-2965 -1125 -168 -134 -494 -458 -621 -619 -271 -342 -465 -676 -622 -1075
-156 -393 -254 -824 -300 -1312 l-5 -48 1729 0 1729 0 4 23 c3 12 10 69 16
127 47 435 186 769 419 1001 150 150 314 249 544 328 420 145 1054 180 1602
90 636 -106 1034 -377 1161 -794 26 -84 28 -102 28 -270 1 -179 0 -181 -33
-278 -133 -384 -534 -644 -1311 -846 -284 -74 -578 -134 -1147 -236 -1145
-205 -1527 -281 -1983 -395 -820 -205 -1442 -458 -1925 -784 -336 -227 -613
-500 -820 -811 -367 -549 -528 -1260 -475 -2090 56 -877 315 -1589 802 -2202
126 -159 404 -438 563 -564 774 -616 1779 -995 3055 -1153 482 -60 887 -80
1445 -73 422 6 547 13 865 47 1210 133 2250 509 3055 1105 793 587 1329 1377
1546 2281 49 206 82 440 104 732 l6 82 -1753 0 -1754 0 -12 -127 c-72 -753
-578 -1290 -1416 -1503 -267 -68 -493 -97 -826 -106 -875 -25 -1544 223 -1848
685 -108 165 -156 323 -154 516 3 508 369 813 1227 1020 229 56 414 90 1165
216 1134 190 1456 251 1896 359 884 217 1521 497 2034 894 133 103 424 392
520 516 375 486 581 1030 666 1755 26 220 27 824 1 1015 -36 266 -89 496 -165
720 -401 1181 -1379 2096 -2757 2582 -500 176 -1058 291 -1675 344 -173 14
-893 27 -1045 18z"
      />
      <path
        d="M21150 28654 c-743 -26 -1467 -178 -2156 -454 -203 -81 -617 -282
-804 -390 -1398 -807 -2451 -2102 -2958 -3640 -437 -1325 -441 -2811 -10
-4130 541 -1655 1729 -3023 3293 -3790 1045 -513 2159 -724 3365 -639 1205 85
2288 444 3107 1031 44 32 84 58 87 58 3 0 6 -209 6 -465 l0 -465 1730 0 1730
0 0 6380 0 6380 -1730 0 -1730 0 0 -505 c0 -278 -3 -505 -6 -505 -3 0 -31 20
-62 44 -84 64 -331 225 -456 296 -157 90 -506 258 -676 325 -630 250 -1344
408 -2050 454 -178 12 -540 20 -680 15z m940 -3163 c196 -26 375 -64 550 -116
1245 -372 2181 -1393 2425 -2648 160 -820 7 -1702 -420 -2416 -233 -388 -556
-740 -924 -1006 -789 -569 -1855 -785 -2816 -570 -827 185 -1538 668 -2019
1372 -302 443 -490 941 -571 1514 -23 156 -31 607 -15 781 141 1556 1270 2808
2765 3067 95 17 189 29 350 45 105 11 537 -4 675 -23z"
      />
      <path
        d="M61783 24393 c3 -2998 7 -4168 15 -4248 26 -248 44 -393 68 -538 176
-1075 615 -1986 1289 -2676 363 -371 708 -624 1170 -856 236 -119 403 -189
637 -266 1099 -363 2472 -355 3568 23 192 66 336 126 535 222 479 232 854 493
1232 860 l113 109 0 -627 0 -626 1705 0 1705 0 0 6380 0 6380 -1715 0 -1715 0
-2 -3637 c-3 -3421 -4 -3646 -21 -3773 -53 -398 -145 -716 -296 -1015 -408
-807 -1172 -1282 -2199 -1366 -197 -16 -548 -6 -720 20 -657 101 -1170 420
-1513 944 -178 272 -314 629 -374 982 -52 306 -48 48 -52 4093 l-4 3752 -1714
0 -1715 0 3 -4137z"
      />
      <path
        d="M75220 17570 l0 -1800 1790 0 1790 0 0 1800 0 1800 -1790 0 -1790 0
0 -1800z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["color"],
};
</script>
