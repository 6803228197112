<template>
  <div
    class="bg-black dark:bg-white w-full h-screen absolute top-0 left-0 z-40"
  >
    <div class="w-full h-24 flex items-center justify-between pl-5">
      <div class="w-full flex items-center  ">
        <span
          @click="changeTheme"
          :class="toggleDark ? 'justify-end' : 'justify-start'"
          class="bg-white dark:bg-black w-12 rounded-full inline-block relative p-1 flex  items-center h-6"
        >
          <span class="bg-black dark:bg-white w-4 h-4 rounded-full"></span>
        </span>
        <span class="font-hslBold ml-2 text-lg text-white dark:text-black">{{
          toggleDark ? "Dark" : "Light"
        }}</span>
      </div>

      <span @click="closeScreen" class="cursor-pointer inline-block  mr-5">
        <IosCloseIcon
          class="fill-current text-white dark:text-black text-5xl"
        />
      </span>
    </div>
    <div class="w-full h-[calc(100%-6rem)] flex ">
      <div
        class="w-1/6 h-5/6 justify-center  items-center flex space-y-5  flex-col"
      ></div>
      <div
        class="w-full h-full justify-center items-start flex space-y-6  flex-col"
      >
        <transition
          enter-active-class="animate__animated animate__fadeInDown animate__fast delay-1"
          leave-active-class="animate__animated animate__fadeOut "
          mode="out-in"
        >
          <span
            v-if="show"
            @click="closeScreen"
            class="text-7xl font-hslBold dark:text-black text-white cursor-pointer"
            >Home</span
          >
        </transition>
        <transition
          enter-active-class="animate__animated animate__fadeInDown animate__fast delay-2"
          leave-active-class="animate__animated animate__fadeOut "
          mode="out-in"
        >
          <router-link v-if="show" :to="{ name: 'Works' }">
            <span class="text-7xl font-hslBold dark:text-black text-white"
              >Works</span
            >
          </router-link>
        </transition>
        <transition
          enter-active-class="animate__animated animate__fadeInDown animate__fast delay-3"
          leave-active-class="animate__animated animate__fadeOut "
          mode="out-in"
        >
          <router-link v-if="show" :to="{ name: 'Blog' }">
            <span class="text-7xl dark:text-black font-hslBold text-white"
              >Blog</span
            >
          </router-link>
        </transition>
        <transition
          enter-active-class="animate__animated animate__fadeInDown animate__fast delay-4"
          leave-active-class="animate__animated animate__fadeOut "
          mode="out-in"
        >
          <router-link v-if="show" :to="{ name: 'Contact' }">
            <span class="text-7xl dark:text-black font-hslBold text-white"
              >Contact</span
            >
          </router-link>
        </transition>
        <div class="w-full  h-1/4 flex items-center  "></div>
      </div>
    </div>
  </div>
</template>

<script>
import IosCloseIcon from "vue-ionicons/dist/ios-close.vue";
export default {
  data() {
    return {
      show: false,
      toggleDark: false,
    };
  },
  methods: {
    closeScreen() {
      this.$emit("screenEvent");
      this.show = false;
    },
    changeTheme() {
      this.toggleDark = !this.toggleDark;
      this.$emit("changeTheme");
    },
  },
  components: {
    IosCloseIcon,
  },
  mounted() {
    this.show = true;
    sessionStorage.theme === "dark"
      ? (this.toggleDark = true)
      : (this.toggleDark = false);
    sessionStorage.theme === "dark" ? (this.logo = "#ffffff") : "#000000";
  },
};
</script>
