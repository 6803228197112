<template>
  <nav class="w-full h-20 sm:h-36 flex items-center justify-center ">
    <transition
      enter-active-class="animate__animated animate__slideInDown "
      leave-active-class="animate__animated animate__slideOutUp "
      mode="out-in"
    >
      <MobileMenu
        v-if="showmobilemenu"
        @changeTheme="changeTheme"
        @screenEvent="showmobilemenu = false"
      />
    </transition>
    <div class="container flex items-center sm:px-0 px-6  justify-between">
      <div class="w-auto h-auto">
        <Logo class="sm:w-auto w-28" :color="logo" />
      </div>
      <span
        @click="showmobilemenu = !showmobilemenu"
        class="flex items-center justify-center w-auto h-auto  cursor-pointer sm:hidden"
      >
        <IosMenuIcon
          class="text-4xl fill-current text-black dark:text-white select-none"
        />
        <ion-icon
          class="text-[2.75rem] text-black dark:text-white select-none"
          name="reorder-two-outline"
        ></ion-icon>
      </span>
      <div
        class="w-auto relative h-auto space-x-10 dark:text-white items-center font-hslMedium text-xl hidden sm:flex"
      >
        <router-link :to="{ name: 'Home' }">
          <span class="nav-items">Home</span>
        </router-link>

        <router-link @click="onRoute" :to="{ name: 'Works' }">
          <span class="nav-items">Works</span>
        </router-link>
        <router-link @click="onRoute" :to="{ name: 'Blog' }">
          <span class="nav-items">Blog</span>
        </router-link>
        <router-link @click="onRoute" :to="{ name: 'Contact' }">
          <span class="nav-items">Contact</span>
        </router-link>
        <span class="flex items-center ">
          <span class="flex flex-col items-center relative  ">
            <span
              @click="dropdown = !dropdown"
              :class="dropdown ? 'rotate-180' : 'rotate-0'"
              class="cursor-pointer transform  flex items-center transition-all rounded-full "
            >
              <IosArrowDownIcon class="text-xl dark:fill-current text-white" />
            </span>
            <transition
              enter-active-class="animate__animated animate__fadeInUp animate__faster "
              leave-active-class="animate__animated animate__fadeOutDown animate__faster"
              mode="out-in"
            >
              <span
                v-if="dropdown"
                class="absolute  top-10 right-0 border border-alphagray flex items-center px-2 rounded-md w-auto h-auto py-1"
              >
                <span
                  @click="changeTheme"
                  :class="toggleDark ? 'justify-end' : 'justify-start'"
                  class="w-9 rounded-full bg-gray-300 dark:bg-gray-900 h-5 transition-all  flex items-center cursor-pointer  p-1"
                >
                  <span
                    class=" transition-all bg-white dark:bg-gray-700   inline-block w-3 h-3 rounded-full"
                  ></span>
                </span>

                <span class="text-base select-none ml-2 ">{{
                  toggleDark ? "Dark" : "Light"
                }}</span>
              </span>
            </transition>
          </span>
        </span>
      </div>
    </div>
  </nav>
</template>

<script>
import Logo from "@/assets/logo.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import IosArrowDownIcon from "vue-ionicons/dist/ios-arrow-down.vue";
import IosMenuIcon from "vue-ionicons/dist/ios-menu.vue";

export default {
  data() {
    return {
      showmobilemenu: false,
      toggleDark: false,
      logo: null,
      dropdown: false,
    };
  },
  mounted() {
    sessionStorage.theme === "dark"
      ? (this.toggleDark = true)
      : (this.toggleDark = false);
    sessionStorage.theme === "dark" ? (this.logo = "#ffffff") : "#000000";
  },
  methods: {
    changeTheme() {
      this.toggleDark = !this.toggleDark;
      if (this.toggleDark === false) {
        document.getElementsByTagName("html")[0].setAttribute("class", "light");
        sessionStorage.theme = "light";
        this.logo = "#000000";
      } else {
        document.getElementsByTagName("html")[0].setAttribute("class", "dark");
        sessionStorage.theme = "dark";
        this.logo = "#ffffff";
      }
    },
    onRoute() {
      this.$emit("davidoff");
    },
  },
  components: {
    MobileMenu,
    Logo,
    IosArrowDownIcon,
    IosMenuIcon,
  },
};
</script>
